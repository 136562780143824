import React, {useEffect, useRef, useState} from 'react';
import * as styles from './css/style.module.scss';
import imgKimura from './img/norihito_kimura.jpg';
import imgTange from './img/eri_tange.jpg';
import imgMatuyama from './img/yuuki_matuyama.jpg';
import imgHujimoto from './img/kensuke_hujimoto.jpg';
import imgYoshitani from './img/kenta_yoshitani.jpg';
import imgKo from './img/humiyu_ko.jpg';
import imgSugiura from './img/taiki_sugiura.jpg';
import imgSuzuki from './img/mari_suzumariko.jpg';
import imgArai from './img/takashi_arai.jpg';
import imgSyoji from './img/yudai_syoji.jpg';
import imgIsida from './img/mai_ishida.jpg';
import imgSakurai from './img/kazuki_sakurai.jpg';
import imgArao from './img/huka_arao.jpg';
import imgIhara from './img/kunihiro_ihara.jpg';
import imgAsai from './img/yuki_asai.jpg';

import {SpTitle} from "../SpTitle";
import {Section4Card} from "../Section4_team_card";

export const Section4Team = (props) => {
    const {yPos} = props;
    const linkRef = useRef(null);
    const [fadeIn_1show, setFadeIn_1show] = useState(false);

    useEffect(() => {
        const pos1 = linkRef.current.getBoundingClientRect().top + yPos

        if (yPos > pos1 - window.innerHeight + (linkRef.current.getBoundingClientRect().top * 0.4)) {
            setFadeIn_1show(true)
        }

    }, [yPos])


    return <div className={styles.wrap}>
        <div className={styles.title}><SpTitle titleText={'TEAM'} iconColor={['#F2685F', '#F48F86', '#F6B6AD']}
                                               clipPathId={'sectionTitle'}/></div>
        <div className={styles.list}>
            <Section4Card position={'CEO'} name={'木村 憲仁'} twitterUrl={'https://twitter.com/norikmr'} youtrustUrl={'https://youtrust.jp/users/nori'} noteUrl={'https://note.com/knorihito'} imgSrc={imgKimura}
                notionUrl={'https://ugoku.notion.site/Kimura-Norihito-7e4165393dbf46b585dd7622d3e85961'} pageYPos={yPos}
            />

            <Section4Card position={'COO'} name={'丹下 恵里'} twitterUrl={'https://twitter.com/tantantantan23'} youtrustUrl={'https://youtrust.jp/users/208e4d7e73984b5706f0a4fc997745c1'} noteUrl={'https://note.com/tantantange'} imgSrc={imgTange}
                          notionUrl={'https://ugoku.notion.site/Tange-Eri-6efa51a4192c40ddbfb477fe0c1e0fd0'} pageYPos={yPos}
            />

            <Section4Card position={'CTO'} name={'松山 勇輝'} twitterUrl={'https://twitter.com/matsumatsu202'} youtrustUrl={'https://youtrust.jp/users/931733a5af49f37d4aae953db90c1fd2'} noteUrl={''} imgSrc={imgMatuyama}
                          notionUrl={'https://ugoku.notion.site/Matsuyama-Yuki-55eaa73406804e46a9d903042308f15e'} pageYPos={yPos}
            />

            <Section4Card position={'Biz-dev/Sales'} name={'藤本 賢介'} twitterUrl={'https://twitter.com/fujiken_mon'} youtrustUrl={'https://youtrust.jp/users/aef183c6f0096f184a3f2f537fc13d17'} noteUrl={'https://note.com/ugoku_mento/n/n813befae557a'} imgSrc={imgHujimoto}
                          notionUrl={'https://ugoku.notion.site/Fujimoto-Kensuke-8c3aa2421a2542b597f8c3b1a87e8ed8'} pageYPos={yPos}
            />

            <Section4Card position={'Engineer'} name={'吉谷 健太'} twitterUrl={'https://twitter.com/KentaYoshitani'} youtrustUrl={'https://youtrust.jp/users/yoshiken'} noteUrl={'https://note.com/ugoku_mento/n/n4f6501543dec'} imgSrc={imgYoshitani}
                          notionUrl={'https://ugoku.notion.site/Yoshitani-Kenta-481310a0233f4579ad11c2aec937d57a'} pageYPos={yPos}
            />

            <Section4Card position={'Marketing'} name={'顧 文瑜'} twitterUrl={'https://twitter.com/kokoko_53'} youtrustUrl={''} noteUrl={''} imgSrc={imgKo}
                          notionUrl={'https://ugoku.notion.site/Fumiyu-Ko-33035eac345048b6bce2f3a21d6664e6'} pageYPos={yPos}
            />

            <Section4Card position={'Coach Success'} name={'鈴木 麻理子'} twitterUrl={'https://twitter.com/suzumari_mento'} youtrustUrl={''} noteUrl={''} imgSrc={imgSuzuki}
                          notionUrl={'https://ugoku.notion.site/Mariko-Suzuki-64ba4cb0569d409794e12b58ace0a330'} pageYPos={yPos}
            />

            <Section4Card position={'Data Scientist'} name={'杉浦 太樹'} twitterUrl={'https://twitter.com/futoiki'} youtrustUrl={'https://youtrust.jp/users/taiki_sugiura'} noteUrl={'https://note.com/ugoku_mento/n/n59346231804f'} imgSrc={imgSugiura}
                          notionUrl={'https://ugoku.notion.site/Sugiura-Taiki-4298202a0058438eb67c54a88605c490'} pageYPos={yPos}
            />

            <Section4Card position={'Product Manager'} name={'荒井 隆之'} twitterUrl={'https://twitter.com/ninjaad'} youtrustUrl={''} noteUrl={''} imgSrc={imgArai}
                          notionUrl={'https://ugoku.notion.site/Arai-Takashi-82672c493ba34527af5a91415d70b8e1'} pageYPos={yPos}
            />

            <Section4Card position={'Biz-dev/Customer Success'} name={'庄司 雄大'} twitterUrl={'https://twitter.com/ShojiYudai'} youtrustUrl={''} noteUrl={''} imgSrc={imgSyoji}
                          notionUrl={'https://ugoku.notion.site/42494fd02cf64e038f33c3cc4225ceb5'} pageYPos={yPos}
            />

            <Section4Card position={'Biz-dev/Customer Success'} name={'宮城 舞'} twitterUrl={'https://twitter.com/maimai_isd'} youtrustUrl={''} noteUrl={''} imgSrc={imgIsida}
                          notionUrl={'https://ugoku.notion.site/Mai-Ishida-3b64f85a832a420daeda713b96070fa3'} pageYPos={yPos}
            />

            <Section4Card position={'Biz-dev/Sales'} name={'櫻井 和樹'} twitterUrl={'https://twitter.com/KazooSakukazoo'} youtrustUrl={''} noteUrl={''} imgSrc={imgSakurai}
                          notionUrl={'https://ugoku.notion.site/Kazuki-Sakurai-f49151a8e32540d8bf4d6f7624b1ab25'} pageYPos={yPos}
            />

            <Section4Card position={'Biz-dev/Customer Success'} name={'荒生 風花'} twitterUrl={''} youtrustUrl={''} noteUrl={''} imgSrc={imgArao}
                          notionUrl={'https://ugoku.notion.site/195a7c2b30d44b02bd4d0c8572c857f1'} pageYPos={yPos}
            />

            <a ref={linkRef} className={`${styles.link} fadeIn ${fadeIn_1show ? 'show' : ''}`} href='https://recruit.mento.co.jp/' target='_blank' rel="noreferrer">
                <div className={styles.link__hover}>
                    <div className={styles.link__hoverImg}>
                        <svg width="100%" height="100%" viewBox="0 0 102 75" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M60.2593 24.4041C56.8109 24.4041 53.6729 25.4326 51.4444 27.3043C51.1405 27.5543 50.8526 27.8231 50.5824 28.109C50.542 28.1527 50.493 28.1875 50.4385 28.2113C50.384 28.2351 50.3251 28.2474 50.2656 28.2474C50.206 28.2474 50.1471 28.2351 50.0926 28.2113C50.0381 28.1875 49.9891 28.1527 49.9487 28.109C47.4099 25.4714 43.7029 24.3999 40.3235 24.3999C39.1965 24.3979 38.0724 24.5147 36.97 24.7485C36.9038 24.7639 36.8422 24.7947 36.7902 24.8384C36.7382 24.8821 36.6972 24.9374 36.6707 25C36.6443 25.0625 36.633 25.1304 36.6378 25.1981C36.6426 25.2658 36.6634 25.3313 36.6985 25.3895L39.1425 29.4729C39.184 29.5417 39.244 29.5974 39.3156 29.6339C39.3872 29.6704 39.4676 29.6862 39.5478 29.6795C39.7978 29.6579 40.0563 29.645 40.3235 29.645C44.9141 29.645 47.4401 32.3687 47.4401 37.3083V52.2132C47.4401 52.3273 47.4855 52.4367 47.5663 52.5174C47.6472 52.5981 47.7568 52.6435 47.8711 52.6435H52.7031C52.8174 52.6435 52.927 52.5981 53.0079 52.5174C53.0887 52.4367 53.1341 52.3273 53.1341 52.2132V37.3083C53.1341 32.3687 55.6601 29.645 60.2506 29.645C64.8412 29.645 67.3671 32.3687 67.3671 37.3083V52.2132C67.3671 52.3273 67.4125 52.4367 67.4934 52.5174C67.5742 52.5981 67.6839 52.6435 67.7982 52.6435H72.5698C72.6841 52.6435 72.7938 52.5981 72.8746 52.5174C72.9554 52.4367 73.0008 52.3273 73.0008 52.2132V36.7532C73.0095 27.6399 66.143 24.4041 60.2593 24.4041Z"
                                fill="white"/>
                            <path
                                d="M33.7504 30.4582C33.7504 28.6712 32.2992 27.2225 30.509 27.2225C28.7188 27.2225 27.2676 28.6712 27.2676 30.4582C27.2676 32.2453 28.7188 33.6939 30.509 33.6939C32.2992 33.6939 33.7504 32.2453 33.7504 30.4582Z"
                                fill="white"/>
                            <path
                                d="M32.7851 37.6655H28.0092C27.7711 37.6655 27.5781 37.8581 27.5781 38.0957V52.2132C27.5781 52.4508 27.7711 52.6435 28.0092 52.6435H32.7851C33.0232 52.6435 33.2161 52.4508 33.2161 52.2132V38.0957C33.2161 37.8581 33.0232 37.6655 32.7851 37.6655Z"
                                fill="white"/>
                            <path
                                d="M12.7943 67.7782C8.90519 68.9857 7.80056 70.243 6.7891 74.7696C6.77484 74.8348 6.73837 74.8933 6.68577 74.9352C6.63316 74.9771 6.5676 75 6.5 75C6.4324 75 6.36684 74.9771 6.31423 74.9352C6.26163 74.8933 6.22516 74.8348 6.2109 74.7696C5.19944 70.243 4.09481 68.9857 0.205683 67.7782C0.145967 67.7593 0.093863 67.7222 0.056893 67.6722C0.0199231 67.6222 0 67.5619 0 67.5C0 67.4381 0.0199231 67.3778 0.056893 67.3278C0.093863 67.2778 0.145967 67.2407 0.205683 67.2218C4.09481 66.0143 5.19944 64.7585 6.2109 60.2304C6.22516 60.1652 6.26163 60.1067 6.31423 60.0648C6.36684 60.0229 6.4324 60 6.5 60C6.5676 60 6.63316 60.0229 6.68577 60.0648C6.73837 60.1067 6.77484 60.1652 6.7891 60.2304C7.80056 64.757 8.90519 66.0143 12.7943 67.2218C12.854 67.2407 12.9061 67.2778 12.9431 67.3278C12.9801 67.3778 13 67.4381 13 67.5C13 67.5619 12.9801 67.6222 12.9431 67.6722C12.9061 67.7222 12.854 67.7593 12.7943 67.7782V67.7782Z"
                                fill="white"/>
                            <path
                                d="M89.7785 8.29671C85.5902 9.58479 84.4006 10.9258 83.3113 15.7542C83.296 15.8238 83.2567 15.8862 83.2001 15.9309C83.1434 15.9756 83.0728 16 83 16C82.9272 16 82.8566 15.9756 82.7999 15.9309C82.7433 15.8862 82.704 15.8238 82.6887 15.7542C81.5994 10.9258 80.4098 9.58479 76.2215 8.29671C76.1572 8.2766 76.1011 8.23702 76.0613 8.18369C76.0215 8.13036 76 8.06603 76 8C76 7.93397 76.0215 7.86964 76.0613 7.81631C76.1011 7.76298 76.1572 7.7234 76.2215 7.70329C80.4098 6.41521 81.5994 5.07572 82.6887 0.2458C82.704 0.176198 82.7433 0.113842 82.7999 0.0691179C82.8566 0.0243935 82.9272 0 83 0C83.0728 0 83.1434 0.0243935 83.2001 0.0691179C83.2567 0.113842 83.296 0.176198 83.3113 0.2458C84.4006 5.07417 85.5902 6.41521 89.7785 7.70329C89.8428 7.7234 89.8989 7.76298 89.9387 7.81631C89.9785 7.86964 90 7.93397 90 8C90 8.06603 89.9785 8.13036 89.9387 8.18369C89.8989 8.23702 89.8428 8.2766 89.7785 8.29671V8.29671Z"
                                fill="white"/>
                            <path
                                d="M101.842 21.704C98.8501 22.5895 98.0004 23.5115 97.2224 26.831C97.2114 26.8789 97.1834 26.9217 97.1429 26.9525C97.1024 26.9832 97.052 27 97 27C96.948 27 96.8976 26.9832 96.8571 26.9525C96.8166 26.9217 96.7886 26.8789 96.7776 26.831C95.9996 23.5115 95.1499 22.5895 92.1582 21.704C92.1123 21.6902 92.0722 21.663 92.0438 21.6263C92.0153 21.5896 92 21.5454 92 21.5C92 21.4546 92.0153 21.4104 92.0438 21.3737C92.0722 21.337 92.1123 21.3098 92.1582 21.296C95.1499 20.4105 95.9996 19.4896 96.7776 16.169C96.7886 16.1211 96.8166 16.0783 96.8571 16.0475C96.8976 16.0168 96.948 16 97 16C97.052 16 97.1024 16.0168 97.1429 16.0475C97.1834 16.0783 97.2114 16.1211 97.2224 16.169C98.0004 19.4885 98.8501 20.4105 101.842 21.296C101.888 21.3098 101.928 21.337 101.956 21.3737C101.985 21.4104 102 21.4546 102 21.5C102 21.5454 101.985 21.5896 101.956 21.6263C101.928 21.663 101.888 21.6902 101.842 21.704V21.704Z"
                                fill="white"/>
                        </svg>
                    </div>

                    <div className={styles.link__hoverText}>
                        YES, YOU!
                    </div>
                </div>

                <div className={styles.link__normal}>
                    <div className={styles.link__normalImg}>
                        <svg width="100%" height="100%" viewBox="0 0 103 98" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M55.2593 30.4041C51.8109 30.4041 48.6729 31.4326 46.4444 33.3043C46.1405 33.5543 45.8526 33.8231 45.5824 34.109C45.542 34.1527 45.493 34.1875 45.4385 34.2113C45.384 34.2351 45.3251 34.2474 45.2656 34.2474C45.206 34.2474 45.1471 34.2351 45.0926 34.2113C45.0381 34.1875 44.9891 34.1527 44.9487 34.109C42.4099 31.4714 38.7029 30.3999 35.3235 30.3999C34.1965 30.3979 33.0724 30.5147 31.97 30.7485C31.9038 30.7639 31.8422 30.7947 31.7902 30.8384C31.7382 30.8821 31.6972 30.9374 31.6707 31C31.6443 31.0625 31.633 31.1304 31.6378 31.1981C31.6426 31.2658 31.6634 31.3313 31.6985 31.3895L34.1425 35.4729C34.184 35.5417 34.244 35.5974 34.3156 35.6339C34.3872 35.6704 34.4676 35.6862 34.5478 35.6795C34.7978 35.6579 35.0563 35.645 35.3235 35.645C39.9141 35.645 42.4401 38.3687 42.4401 43.3083V58.2132C42.4401 58.3273 42.4855 58.4367 42.5663 58.5174C42.6472 58.5981 42.7568 58.6435 42.8711 58.6435H47.7031C47.8174 58.6435 47.927 58.5981 48.0079 58.5174C48.0887 58.4367 48.1341 58.3273 48.1341 58.2132V43.3083C48.1341 38.3687 50.6601 35.645 55.2506 35.645C59.8412 35.645 62.3671 38.3687 62.3671 43.3083V58.2132C62.3671 58.3273 62.4125 58.4367 62.4934 58.5174C62.5742 58.5981 62.6839 58.6435 62.7982 58.6435H67.5698C67.6841 58.6435 67.7938 58.5981 67.8746 58.5174C67.9554 58.4367 68.0008 58.3273 68.0008 58.2132V42.7532C68.0095 33.6399 61.143 30.4041 55.2593 30.4041Z"
                                fill="#002649"/>
                            <path
                                d="M28.7504 36.4582C28.7504 34.6712 27.2992 33.2225 25.509 33.2225C23.7188 33.2225 22.2676 34.6712 22.2676 36.4582C22.2676 38.2453 23.7188 39.6939 25.509 39.6939C27.2992 39.6939 28.7504 38.2453 28.7504 36.4582Z"
                                fill="#002649"/>
                            <path
                                d="M27.7851 43.6655H23.0092C22.7711 43.6655 22.5781 43.8581 22.5781 44.0957V58.2132C22.5781 58.4508 22.7711 58.6435 23.0092 58.6435H27.7851C28.0232 58.6435 28.2161 58.4508 28.2161 58.2132V44.0957C28.2161 43.8581 28.0232 43.6655 27.7851 43.6655Z"
                                fill="#002649"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M44.969 81.9379C65.3864 81.9379 81.9379 65.3864 81.9379 44.969C81.9379 24.5516 65.3864 8 44.969 8C24.5516 8 8 24.5516 8 44.969C8 65.3864 24.5516 81.9379 44.969 81.9379ZM44.969 89.9379C69.8046 89.9379 89.9379 69.8046 89.9379 44.969C89.9379 20.1333 69.8046 0 44.969 0C20.1333 0 0 20.1333 0 44.969C0 69.8046 20.1333 89.9379 44.969 89.9379Z"
                                  fill="#002649"/>
                            <rect x="78.6562" y="68.5" width="33.7153" height="8" rx="2"
                                  transform="rotate(45 78.6562 68.5)" fill="#002649"/>
                        </svg>
                    </div>

                    <div className={styles.link__normalText}>
                        MAYBE YOU..?
                    </div>
                </div>
            </a>
        </div>
    </div>
}
